<script setup lang="ts">
interface CitySegmentsProps {
  geojson: GeoJSON.FeatureCollection<GeoJSON.LineString>
  selected?: string[]
  layerId?: string
}

defineOptions({
  name: 'CitySegments',
})

const props = withDefaults(defineProps<CitySegmentsProps>(), {
  geojson: () => newFeatureCollection(),
  selected: () => [],
  layerId: 'city-segments',
})

const emit = defineEmits(['ready'])

const { geojson } = toRefs(props)

const { classBreaks } = useClassBreaks(useExtractMean(geojson))
const colorBreaks = useExpressionColorFromBreaks(classBreaks)

const paintExpression = computed(() => {
  return {
    'line-color': colorBreaks.value,
    'line-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.6,
      1,
    ],
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      12,
      8,
    ],
  }
})
</script>

<template>
  <MapLibrePopup
    :offset="[0, -5]"
    :layer-id="layerId"
  >
    <template #element="{ feature }">
      <div class="flex w-28 flex-col whitespace-normal text-xs">
        <p>{{ feature?.properties?.name }}</p>
      </div>
    </template>
  </MapLibrePopup>

  <MapLibreSourceGeojson
    :id="layerId"
    :layer-props="{
      type: 'line',
      paint: paintExpression,
      layout: {
        'line-cap': 'round', // round | butt | square
        'line-join': 'round', // round | bevel | miter
      },
    }"
    :data="geojson"
    :geojson-options="{
      promoteId: 'name',
    }"
    :selected-features="selected || []"
    @ready="() => emit('ready')"
  />
</template>
