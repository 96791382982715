<script setup lang="ts">
import type { LngLatLike } from 'maplibre-gl'
import type { CellsData } from '@/types/maps.types'
import type { GeometryType } from '@/types/index.types'
import type { ClassBreaks } from '@/composables/classBreaks'
import type { Network, NetworkData } from '@/composables/cyclability'

interface CyclabilityMapProps {
  zoom: number
  center: LngLatLike
  data: {
    areas?: {
      data: CellsData
      classbreaks: ClassBreaks
    }
    network?: {
      roads: Network
      data?: NetworkData
      classbreaks?: ClassBreaks
    }
    roads?: {
      geojson: GeoJSON.FeatureCollection
    }
  }
  geometryType?: GeometryType | null
  legendTitle?: string
  isLoading?: boolean
}

defineOptions({
  name: 'CyclabilityMap',
})

withDefaults(defineProps<CyclabilityMapProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({}),
})

const emit = defineEmits(['on-zoom'])

const { selectedArea } = useSelectedAreaFromUrl()

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
    @zoomend="({ zoom }) => emit('on-zoom', zoom)"
  >
    <CellsGrid
      v-if="data.areas && (geometryType && isCellsType(geometryType))"
      :data="data.areas.data"
      :class-breaks="data.areas.classbreaks"
      :cells-type="geometryType"
      :selected="selectedArea"
    />

    <CityNetwork
      v-if="data.network"
      :network="data.network.roads"
      :data="data.network.data"
      :class-breaks="data.network.classbreaks"
      :is-loading="isLoading"
    />

    <DefineTemplate>
      <DLoader v-if="isLoading" />

      <MapLegend>
        <template
          v-if="legendTitle"
          #title
        >
          {{ legendTitle }}
        </template>
      </MapLegend>

      <div id="teleport-map-legend" />
    </DefineTemplate>

    <SidebarMobileControls>
      <template #default="{ isMobile }">
        <MapLibreControl
          v-if="!isMobile"
          position="bottom-left"
        >
          <ReuseTemplate />
        </MapLibreControl>
      </template>

      <template #mobile>
        <ReuseTemplate />
      </template>
    </SidebarMobileControls>
  </MapLibre>
</template>
