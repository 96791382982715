<script setup lang="ts">
import type { Map, MapLayerMouseEvent, MapSourceDataEvent, SourceSpecification } from 'maplibre-gl'
import { useSource } from './layerSource'

defineOptions({
  name: 'MapLibreSource',
})

// see for source properties https://maplibre.org/maplibre-style-spec/sources/
const props = withDefaults(defineProps<MapLibreSourceProps>(), {
  source: () => ({
    type: 'geojson',
    data: newFeatureCollection(),
  }),
})

const emit = defineEmits(['click'])

interface MapLibreSourceProps {
  id: string
  source: SourceSpecification
}

const map = inject<Ref<Map | undefined>>('map', ref())
const ready = ref(false)
const { id } = toRefs(props)

const { removeSource, updateSource, getSource } = useSource(id)

onMounted(() => {
  updateSource(props.source)

  map.value?.on('data', (e: MapSourceDataEvent) => {
    if (e.sourceId === id.value && e.isSourceLoaded) {
      set(ready, true)
    }
  })

  map.value?.on('click', id.value, onClick)
})

onBeforeUnmount(() => {
  get(map)?.off('click', get(id), onClick)
  set(ready, false)
  removeSource()
})

function onClick(event: MapLayerMouseEvent) {
  emit('click', event)
}
</script>

<template>
  <slot
    v-if="ready"
    :get-source="() => getSource()"
  />
</template>
