<script setup lang="ts">
import { ROAD_TYPES } from '@cd/screen-data'
import type { NetorkLineID, Network, NetworkData, RoadLines } from '@/composables/cyclability'
import type { ClassBreaks } from '@/composables/classBreaks'

interface NetwotkProperties {
  infra_type_key: number
}

type RoadFeatures = GeoJSON.Feature<GeoJSON.LineString, NetwotkProperties>

defineOptions({
  name: 'CityNetwork',
})

const props = withDefaults(defineProps<{
  layerId?: string
  network: Network
  data?: NetworkData
  labels?: string[]
  classBreaks?: ClassBreaks
  isLoading?: boolean
}>(), {
  layerId: 'city-network',
})

const emit = defineEmits(['ready'])

const types = ROAD_TYPES
const roadTypesVisible = ref<string[]>([])
const geojson = shallowRef<GeoJSON.FeatureCollection<GeoJSON.LineString, NetwotkProperties> | null>(null)

const { classBreaks: typesClassBreaks } = useClassBreaks(Object.keys(types).map(n => Number(n)), types.length - 1, 'YlGnBu')

const usedClassBreaks = computed(() => {
  return props.classBreaks || typesClassBreaks.value
})

const usedPropertyForClassBreaks = computed(() => {
  return props.classBreaks ? 'mean' : 'infra_type_key'
})

const paintColor = useExpressionColorFromBreaks(usedClassBreaks, usedPropertyForClassBreaks)

function makeGeojson(network: Network, data?: NetworkData) {
  geojson.value = newFeatureCollection<GeoJSON.LineString, NetwotkProperties>(
    network.reduce<RoadFeatures[]>((acc, networkType: RoadLines, index) => {
      if (!networkType.lines || !networkType.lines.length) {
        return acc
      }

      if (!roadTypesVisible.value.includes(networkType.roadType)) {
        roadTypesVisible.value.push(networkType.roadType)
      }

      const features = networkType.lines.map((line, lineIndex) => {
        const lineId = `${index}_${lineIndex}` as NetorkLineID

        return {
          type: 'Feature',
          properties: {
            infra_type_key: types.indexOf(networkType.roadType),
            mean: data?.get(lineId) || null,
          },
          geometry: {
            type: 'LineString',
            coordinates: line,
          },
        } as RoadFeatures
      })

      return [...acc, ...features]
    }, []),
  )
}

watch([() => props.network, () => props.data], ([network, data]) => {
  roadTypesVisible.value = []
  makeGeojson(network, data)
}, { immediate: true })
</script>

<template>
  <MapLibreSourceGeojson
    v-if="geojson"
    :id="layerId"
    :layer-props="{
      type: 'line',
      paint: {
        'line-width': 1,
        'line-opacity': 1,
        'line-color': paintColor || '#576067',
      },
    }"
    :data="geojson"
    @ready="() => emit('ready')"
  />

  <MapLibreControl position="bottom-left">
    <DLoader v-if="isLoading" />
    <MapLegend
      v-else
      :class-breaks="usedClassBreaks.filter((_, i) => classBreaks || roadTypesVisible.includes(types[i]))"
    >
      <template
        v-if="classBreaks && labels || !classBreaks"
        #label="{ index }"
      >
        {{ classBreaks ? labels?.[index] : roadTypesVisible[index] }}
      </template>
    </MapLegend>
  </MapLibreControl>
</template>
