import defu from 'defu'

type ParamType = string | null | undefined

interface ParamOptions<T = ParamType> {
  defaultValue?: T | Ref<T>
  nullValue?: string | null
  parse?: (v: string | null) => T
  format?: (v: T) => any
  pause?: Ref<boolean>
}

export function useParam<T = string>(paramName: string, options?: ParamOptions<T>): ComputedRef<T | null> {
  const route = useRoute()

  options = defu(options, {
    nullValue: null,
  })

  const param = computed((): T | null => {
    const routeParam = route.params[paramName]

    if (routeParam && routeParam !== options.nullValue) {
      if (Array.isArray(routeParam)) {
        return routeParam[0] as T
      } else {
        return routeParam as T
      }
    }

    return unref(options.defaultValue) || null
  })

  return param
}

export function useReactiveParam<T = string>(paramName: string, options?: ParamOptions<T>): WritableComputedRef<T | undefined> {
  const route = useRoute()
  const router = useRouter()

  options = {
    nullValue: null,
    ...options,
  }

  const param = computed({
    get: (): T | undefined => {
      const routeParam = route.params[paramName]
      let param = null

      if (routeParam && routeParam !== options.nullValue) {
        if (Array.isArray(routeParam)) {
          param = routeParam[0]
        } else {
          param = routeParam
        }

        return options.parse ? options.parse(param) : param as T
      }

      return options.defaultValue !== undefined ? unref(options.defaultValue) : undefined
    },
    set: async (value?: T) => {
      if (options.pause?.value) {
        return
      }

      await nextTick()
      const newParams = { ...route.params }

      if (value) {
        if (options.format) {
          value = options.format(value)
        }

        if (Array.isArray(value)) {
          newParams[paramName] = value.map(v => v.toString()).join(',')
        } else {
          newParams[paramName] = value?.toString() || ''
        }
      } else {
        newParams[paramName] = ''
      }

      const from = route.fullPath
      const to = router.resolve({ params: newParams }).fullPath

      if (from !== to) {
        router.push({ params: newParams })
      }
    },
  })

  return param
}

export function useReactiveParams(params: Record<string, { name: string } & ParamOptions>) {
  const route = useRoute()
  const router = useRouter()

  const paramsRef = ref<Record<string, ParamType>>({})
  const reactiveParams: Record<string, WritableComputedRef<ParamType>> = {}

  for (const [paramName, options] of Object.entries(params)) {
    paramsRef.value[options.name] = route.params[options.name]?.toString() || options.nullValue

    reactiveParams[paramName] = computed({
      get: () => {
        const routeParam = paramsRef.value[options.name]
        let param = null

        if (routeParam && routeParam !== options.nullValue) {
          if (Array.isArray(routeParam)) {
            param = routeParam[0]
          } else {
            param = routeParam
          }

          return options.parse ? options.parse(param) : param
        }

        return options.defaultValue !== undefined ? unref(options.defaultValue) : undefined
      },
      set: async (value) => {
        if (options.pause?.value) {
          return
        }

        if (value) {
          if (options.format) {
            value = options.format(value)
          }

          if (Array.isArray(value)) {
            paramsRef.value[options.name] = value.map(v => v.toString()).join(',')
          } else {
            paramsRef.value[options.name] = value?.toString() || ''
          }
        } else {
          paramsRef.value[options.name] = ''
        }
      },
    })
  }

  watch(paramsRef, (newParams /* oldParams */) => {
    newParams = toRaw(newParams)

    const from = route.fullPath
    const to = router.resolve({ ...route, params: newParams }).fullPath

    if (from !== to) {
      router.push({ params: newParams })
    }
  }, { deep: true, immediate: true })

  return reactiveParams
}
