<script setup lang="ts">
import type { LngLatLike } from 'maplibre-gl'
import type { ClassBreaks } from '@/composables/classBreaks'
import type { Network, NetworkData } from '@/composables/cyclability'
import type { CellsData } from '@/types/maps.types'
import type { GeometryType } from '@/types/index.types'

defineOptions({
  name: 'CyclabilityMapSegments',
})

const props = withDefaults(defineProps<CyclabilityMapSegmentsProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({}),
})

const emit = defineEmits(['onZoom'])

interface CyclabilityMapSegmentsProps {
  zoom: number
  center: LngLatLike
  data: {
    areas?: {
      data: CellsData
      classbreaks: ClassBreaks
    }
    network?: {
      roads: Network
      data?: NetworkData
      classbreaks?: ClassBreaks
    }
    roads?: {
      geojson: GeoJSON.FeatureCollection<GeoJSON.LineString>
    }
    pois?: {
      geojson: GeoJSON.FeatureCollection<GeoJSON.Point>
    }
  }
  geometryType?: GeometryType | null
  legendTitle?: string
  isLoading?: boolean
}

const { selectedArea } = useSelectedAreaFromUrl()

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()

const selectedPoi = computed(() => {
  return selectedArea.value
    ? props.data.pois?.geojson.features.find(f => f.properties?.name === selectedArea.value[0])
    : undefined
})
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
    @zoomend="({ zoom }) => emit('onZoom', zoom)"
  >
    <MapLibreLayerKeepOrder>
      <template #default="scoped">
        <CitySegments
          v-if="data.roads"
          layer-id="city-segments"
          :geojson="data.roads.geojson"
          :selected="selectedArea"
          @ready="() => scoped.reorder()"
        />

        <CityNetwork
          v-if="data.network"
          layer-id="city-network"
          :network="data.network.roads"
          :data="data.network.data"
          :class-breaks="data.network.classbreaks"
          :is-loading="isLoading"
          @ready="() => scoped.reorder()"
        />

        <CellsGrid
          v-if="data.areas && (geometryType && isCellsType(geometryType))"
          layer-id="cells-grid"
          :data="data.areas.data"
          :class-breaks="data.areas.classbreaks"
          :cells-type="geometryType"
          @ready="() => scoped.reorder()"
        />
      </template>

      <!--
      <MapLibreSourceGeojson
        v-if="data.pois"
        layer-id="city-pois"
        :data="data.pois.geojson"
        :layer-props="{
          type: 'circle',
          paint: {
            'circle-color': getSelectedColorCase('#20757C'),
            'circle-stroke-color': '#fff',
            'circle-stroke-width': 2,
            'circle-stroke-opacity': 0.5,
            'circle-radius': 4,
          },
        }"
        :geojson-options="{
          promoteId: 'name',
        }"
        :selected-features="selectedArea || []"
      /> -->
    </MapLibreLayerKeepOrder>

    <MapSelectArea :layers-ids="['city-segments']">
      <DefineTemplate>
        <MapLegend>
          <template
            v-if="legendTitle"
            #title
          >
            {{ legendTitle }}
          </template>
        </MapLegend>
      </DefineTemplate>

      <SidebarMobileControls>
        <template #default="{ isMobile }">
          <MapLibreControl
            v-if="!isMobile"
            position="bottom-left"
          >
            <ReuseTemplate />
          </MapLibreControl>
        </template>

        <template #mobile>
          <ReuseTemplate />
        </template>
      </SidebarMobileControls>
    </MapSelectArea>
  </MapLibre>

  <MapSidebar v-if="selectedArea.length > 0">
    <DAsyncImg
      v-if="selectedPoi"
      :src="selectedPoi?.properties?.path"
      class="aspect-video w-full h-auto"
    />
  </MapSidebar>
</template>
