export function isString(value: any): value is string {
  return typeof value === 'string' || value instanceof String
}

export function isPromise(value: any | Promise<any>): value is Promise<any> {
  return Boolean(value && typeof value.then === 'function')
}

export async function wait(time: number) {
  return new Promise(resolve => setTimeout(resolve, time))
}

export function newFeatureCollection<T extends GeoJSON.Geometry | null, P>(features: GeoJSON.Feature<T, P>[] = []): GeoJSON.FeatureCollection<T, P> {
  return {
    type: 'FeatureCollection',
    features,
  }
}
