<script setup lang="ts">
// import type { ClassBreaks } from '@/composables/classBreaks'
import type { ClassBreaks } from '@/composables/classBreaks'
import type { Network } from '@/composables/cyclability'
import type { GeometryType } from '@/types/index.types'
import type { CellsData } from '@/types/maps.types'

defineOptions({
  name: 'CyclabilityMapPath',
})

const { t } = useI18n()
const navigationStore = useNavigationStore()
const cityStore = useCityStore()
const { slug, city, isLoading } = storeToRefs(cityStore)

const showNetwork = ref(false)

const mapOptions = reactive<{
  zoom: number
  center?: [number, number]
  bounds?: [[number, number], [number, number]]
}>({
  zoom: 7, // TODO real default
  center: undefined, // TODO real default
  bounds: undefined, // TODO real default
})

const { availableGeometries, availableAreasMetrics, areaActiveFile, geometryType, areasMetric, availableNetworks, networkType } = useCyclability(slug, mapOptions)
const { areasData, areasBreaks, loading: areasLoading } = useCyclabilityAreas(slug)
const { network, loading } = useCyclabilityNetwork(slug)
const { roadsGeojson, poisGeojson } = useCyclabilityRoads(slug)

const data = computed(() => {
  const d: {
    areas: { data: CellsData, classbreaks: ClassBreaks }
    network?: { roads: Network }
    roads: { geojson: GeoJSON.FeatureCollection<GeoJSON.LineString> }
    pois?: { geojson: GeoJSON.FeatureCollection<GeoJSON.Point> }
  } = {
    areas: {
      data: unref(areasData),
      classbreaks: unref(areasBreaks),
    },
    roads: {
      geojson: unref(roadsGeojson),
    },
    pois: {
      geojson: unref(poisGeojson),
    },
  }

  if (showNetwork.value) {
    d.network = {
      roads: network.value,
    }
  }

  return d
})

watch(city, (city, lastCity) => {
  if (city && city.name !== lastCity?.name) {
    mapOptions.center = [city.center?.lng, city.center?.lat]
    mapOptions.zoom = city.default_zoom
  }
}, { immediate: true })
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="relative h-full flex-1 overflow-hidden">
      <Teleport
        v-if="navigationStore.filtersIsMounted"
        to="#teleport-sidebar"
      >
        <DSelectPicker
          v-if="availableAreasMetrics.length"
          v-model="areasMetric"
          icon="hexagones"
          :options="availableAreasMetrics.map(({ name, label }) => ({ value: name, label }))"
          :label="t('Metric')"
          :is-loading="isLoading"
        />

        <DSelectPicker
          v-model="geometryType"
          icon="hexagones"
          :options="[
            {
              label: t('Auto (zoom level)'),
              value: null,
              default: true,
            },
            ...availableGeometries.map(({ name, label }) => ({ value: name, label })),
          ]"
          :label="t('Statistical area')"
          :is-loading="isLoading"
          :default-text="`${areaActiveFile?.name || geometryType} (auto)`"
        />

        <DField class="bg-grey-50 hover:bg-blue-100 text-slate-500 rounded-md py-1 px-2 !mb-0">
          <template #label>
            {{ t('Additional layers') }}
          </template>

          <DInputCheckbox v-model="showNetwork">
            {{ t('Cycling network') }}
          </DInputCheckbox>
        </DField>

        <DSelectPicker
          v-if="showNetwork"
          v-model="networkType"
          icon="hexagones"
          :label="t('Network')"
          :is-loading="isLoading"
          :options="availableNetworks.map(({ name, label }) => ({ value: name, label }))"
        />
      </Teleport>

      <CyclabilityMapSegments
        v-bind="mapOptions"
        :data="data"
        :is-loading="isLoading || areasLoading || loading"
        :geometry-type="areaActiveFile?.name as GeometryType || geometryType"
        :legend-title="t('City Path Bikeability')"
        @on-zoom="(zoom: number) => {
          mapOptions.zoom = zoom
        }"
      />
    </div>
  </div>
</template>
