<script setup>
defineOptions({
  name: 'MapSidebar',
})

const isOpen = ref(true)
</script>

<template>
  <div class="relative overflow-visible w-1 z-sidebar">
    <div
      class="absolute inset-y-0 -right-full z-sidebar w-full max-w-md bg-grey-50 transition-all overflow-y-auto"
      :class="{
        '!right-0': isOpen,
      }"
    >
      <DIconButton
        class="absolute top-1/2 -left-4 z-hop -mt-4"
        size="sm"
        :path="`chevron-${isOpen ? 'right' : 'left'}`"
        variant="fill-primary"
        @click="isOpen = !isOpen"
      />

      <slot />
    </div>
  </div>
</template>
